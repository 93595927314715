import { z } from 'zod'

export const SELLER_AUTH_CODE_LENGTH = 8

export const sellerIdSchema = z
  .string({
    required_error: 'errors.missing_id',
    invalid_type_error: 'errors.invalid_id',
  })
  .length(14, { message: 'errors.invalid_id' })
  .startsWith('slr_', { message: 'errors.invalid_id' })

const emailSchema = z
  .string()
  .email({ message: 'errors.email_is_invalid' })
  .min(3, { message: 'errors.email_is_too_short' })
  .max(255, { message: 'errors.email_is_too_long' })

const authCodeSchema = z
  .string()
  .min(SELLER_AUTH_CODE_LENGTH, {
    message: 'errors.authentication_code_is_too_short',
  })
  .max(SELLER_AUTH_CODE_LENGTH, {
    message: 'errors.authentication_code_is_too_long',
  })

export const sellerRegisterSchema = z.object({
  email: emailSchema,
})

export const sellerLoginSchema = z.object({
  email: emailSchema,
})

export const sellerAuthenticateSchema = z.object({
  code: authCodeSchema,
})

const notificationCategorySchema = z.enum(['orders.new', 'news'])
const notificationMethodSchema = z.enum(['email', 'push'])
const notificationMethodsSchema = z.array(notificationMethodSchema)

export const sellerNotificationSettingsSchema = z
  .record(notificationCategorySchema, notificationMethodsSchema)
  .or(z.literal('').transform(() => null))
  .or(z.null())

export type SellerNotificationSettings = z.infer<
  typeof sellerNotificationSettingsSchema
>

export const patchSellerSchema = z
  .object({
    name: z.string().optional(),
    notificationSettings: sellerNotificationSettingsSchema,
  })
  .partial()

export type PatchSeller = z.infer<typeof patchSellerSchema>
